@import url('https://fonts.googleapis.com/css2?family=Kalam&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap');

  .each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    height: 100vh;
   filter: brightness(70%);
  }

.aboutUsHeader{
  text-align: center;
  color:#000000;
  font-family: 'Cormorant Garamond', serif;
  font-size: 4rem;
  margin-bottom: 4rem;
  margin-top: 3rem;
}
  
.aboutUsBackground{
  width: 100%;
  height:auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.table{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  height: auto;
}
.recordInTable{
  width: 48%;
  height: auto;
  background-color: white;
  color: #000000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 1rem;
  padding: 2rem 1%;
}

.recordInTable2{
  padding: 2rem 1%;
  border-radius: 1rem;
  width: 48%;
  height: auto;
  background-color: #888888;
  color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: large;
}

.tableHeader{
  font-size: 2rem;
  text-align: center;
}

.tableDescribe{
  text-align: center;
  margin-top: 0.5rem;
  font-size: larger;
}

.teamBackground{
  margin-top: 5rem;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.teamHeader
  {
    text-align: center;
    color:#000000;
    font-family: 'Cormorant Garamond', serif;
    font-size: 4rem;
    margin-bottom: 2rem;
  }

  .profilExample{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 60%;
    height: auto;
    margin-bottom: 2rem;
  }

  .teamPortret{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .portretimg{
    width: 17rem;
    border-radius: 50%;
  }

  .teamName{
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
    color:#000000;
    font-family: 'Cormorant Garamond', serif;
    font-size: 3rem;
  }

  .teamDescribe{
    margin-top: 1rem;
    width: 80%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    font-size: large;
    font-family: 'Roboto', sans-serif;
    color: gray;
  }
.offerBackground{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .offerMenu{
    width: 80%;
    height: auto;
  }

  .offerBlocks {
    display: flex;
    width: auto;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .offerBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 0.1rem solid #dd9ecd ; */
    border-radius: 1rem;
    font-family: 'Roboto', sans-serif;
    padding: 1rem;
    margin: 1rem 0.5rem;
    font-size: 1.5rem;
    width: 600px;
    transition: color 1s, background-color 1s, border-color 1s;
    cursor: pointer;
    text-align: center;
    -webkit-box-shadow: -18px 12px 29px 7px rgba(86, 86, 100, 0.31);
    -moz-box-shadow: -18px 12px 29px 7px rgba(86, 86, 100, 0.31);
    box-shadow: -18px 12px 29px 7px rgba(86, 86, 100, 0.31);
  }

  .offerBlocks a {
    color: #000000;
    text-decoration: none;
  }

  .offerBlock:hover {
    background-color: #cccccc;
    color: white;
    /* border-color: bisque; */
  }


/*   .offerBackground{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .offerMenu{
    width: 80%;
    height: auto;
  }

  .offerBlocks {
    display: flex;
    width: auto;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .offerBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.1rem solid #dd9ecd ;
    border-radius: 1rem;
    font-family: 'Roboto', sans-serif;
    padding: 1rem;
    margin: 1rem 0.5rem;
    font-size: 2rem;
    width: auto;
    transition: color 1s, background-color 1s, border-color 1s;
    cursor: pointer;
    text-align: center;
  }

  .offerBlocks a {
    color: #000000;
    text-decoration: none;
  }

  .offerBlock:hover {
    background-color: #dd9ecd;
    color: bisque;
    border-color: bisque;
  } */

  .list {
    margin-top: 5rem;
    width: 100%;
    height: auto;
  }

  .list ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
  }

  .list ul ul {
    color: #dd9ecd;
    font-size: 2rem;
  }

  .list ul ul li {
    color: #000000
  }

  .list ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    padding: 0.7rem 0;
  }

  .otherInfo {
    margin-top: 2rem;
    font-size: 1.2rem;
    display: flex;
    margin-bottom: 2rem;
    color: #000000;
  }

 .a_booksyBtn {
    text-decoration: none;
    color: #dd9ecd;
  }

  .booksyBtn {
    width: 40%;
    font-weight:600 ;
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 1rem 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
    font-size: 2rem;
    font-family: 'Cormorant Garamond', serif;
    color: #cccccc;
    border: 0.1rem solid #cccccc;
    transition: background-color 1s, color 1s, border-color 1s;
  }

  .booksyBtn:hover {
    background-color: #cccccc;
    color: white;
    /* border-color: bisque; */
  }
/*   .a_booksyBtn {
    text-decoration: none;
    color: #dd9ecd;
  }

  .booksyBtn {
    width: 40%;
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 1rem 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
    font-size: 2rem;
    font-family: 'Cormorant Garamond', serif;
    color: #dd9ecd;
    border: 0.1rem solid #dd9ecd;
    transition: background-color 1s, color 1s, border-color 1s;
  }

  .booksyBtn:hover {
    background-color: #dd9ecd;
    color: bisque;
    border-color: bisque;
  }
 
  .header {
    text-decoration: underline #dd9ecd
  }
*/
.ArrowAndText
  {
    color:#dd9ecd;
    font-family: 'Cormorant Garamond', serif;
    font-size: 4rem;
  }

.galleryBackground{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: white;
}

.card
  {
    padding: 1rem;
  }
.contact
    { 
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      flex-direction: column;
      background-color: #cccccc;
      font-family: 'Cormorant Garamond', serif;
      color: white;
    }

  .firstRowContact
    {
      padding-top: 3rem;
      display: flex;
      width: 80%;
      height:auto;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding-bottom: 2rem;
    }

    .location
      {
        display: flex;
        font-weight: 400;
        font-size: 2.8rem;
        flex-direction: column;
        justify-content: flex-start;
      }

      .location a
      {
        color: white;
        text-decoration: none;
      }

      .locationButton
        {
          display: flex;
          margin-top: 1.5rem;
          font-family: 'Roboto', sans-serif;
          font-size: 1rem;
          width:15rem;
          border-style: solid;
          justify-content: center;
          width: fit-content;
          align-items: center;
          padding: 0.5rem 0.8rem 0.5rem 0.8rem;
          transition: background-color 0.3s;
          cursor: pointer;
          border-width: 0.12rem;
        }

    .locationButton:hover
      {
        background-color: white;
      }

    .openHours
      {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 2.5rem;
        flex-direction: column;
      }

    .hours
      {
        margin-top: 1rem;
        font-size:2rem
      }

    .lineContact
      {
        width: 80%;
        height: 0.1rem;
        background-color: white;

      }

    .secondRowContact
    {
      display: flex;
      width: 80%;
      height:auto;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding: 4rem 0 4rem 0;
    }

    .phoneNumber
      {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 3rem;
      }

    .mediaContact
      {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 10rem;
      }

    .mediaContact a
      {
        text-decoration: none;
        color: white;
        margin: 0;
        padding: 0;
        transition: color 0.3s;
      }

    .mediaContact a:hover
      {
        color: #dd9ecd;
      }
    

    .emailContact
      {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 3rem;
      }

      .emailContact a
        {
          color: white;
          text-decoration: none;
        }

.footer
{
  background-color: #cccccc;
  color: white;
  /* font-size: large; */
  padding: 0.5rem;
  display: flex;
  justify-content: center;
}

/*   .contact
    { 
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      flex-direction: column;
      background-color: #dd9ecd;
      font-family: 'Cormorant Garamond', serif;
      color: white;
    }

  .firstRowContact
    {
      padding-top: 3rem;
      display: flex;
      width: 80%;
      height:auto;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding-bottom: 2rem;
    }

    .location
      {
        display: flex;
        font-weight: 400;
        font-size: 2.8rem;
        flex-direction: column;
        justify-content: flex-start;
      }

      .location a
      {
        color: white;
        text-decoration: none;
      }

      .locationButton
        {
          display: flex;
          margin-top: 1.5rem;
          font-family: 'Roboto', sans-serif;
          font-size: 1rem;
          width:15rem;
          border-style: solid;
          justify-content: center;
          width: fit-content;
          align-items: center;
          padding: 0.5rem 0.8rem 0.5rem 0.8rem;
          transition: background-color 0.3s;
          cursor: pointer;
          border-width: 0.12rem;
        }

    .locationButton:hover
      {
        background-color: white;
      }

    .openHours
      {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 2.5rem;
        flex-direction: column;
      }

    .hours
      {
        margin-top: 1rem;
        font-size:2rem
      }

    .lineContact
      {
        width: 80%;
        height: 0.1rem;
        background-color: white;

      }

    .secondRowContact
    {
      display: flex;
      width: 80%;
      height:auto;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding: 4rem 0 4rem 0;
    }

    .phoneNumber
      {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 3rem;
      }

    .mediaContact
      {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 10rem;
      }

    .mediaContact a
      {
        text-decoration: none;
        color: white;
        margin: 0;
        padding: 0;
        transition: color 0.3s;
      }

    .mediaContact a:hover
      {
        color: #dd9ecd;
      }
    

    .emailContact
      {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 3rem;
      }

      .emailContact a
        {
          color: white;
          text-decoration: none;
        }

.footer
{
  background-color: #dd9ecd;
  color: bisque;
  font-size: large;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
}
 */

  @media screen {
    @media (max-width: 1300px){

      .table{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
      }

      .aboutUsDescription{
        font-size: 1.4rem;
      }

      .secondRowContact{
        flex-direction: column;
        align-items: center;
        justify-content:space-between;
        padding: 2rem 0 2rem 0;
        height: 15rem;
      }
    }
    @media (max-width: 850px){
      .table{
        width: 100%;
      }
      .recordInTable{
        margin-bottom: 3rem;
        width: 17rem;
      }
      .recordInTable2{
        margin-bottom: 3rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        width: 17rem;
      }
      .profilExample{
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .teamDescribe{
        width: auto;
      }

      .offerMenu{
        width: auto;
      }


      .offerBlock {
        margin: 0.7rem 0.5rem;
        font-size: 1rem;
      }
    
      .list {
        margin-top: 5rem;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: auto;
      }
    
      .list ul ul {
        color: #dd9ecd;
        font-size: 1rem;
      }
    
      .list ul li {
        display: flex;
        justify-content:space-between;
        align-items: center;
        font-family: 'Roboto', sans-serif;
        font-size: 0.9rem;
        padding: 0.7rem 0;
      }
    
      .otherInfo {
        margin-top: 2rem;
        font-size: 0.8rem;
        display: flex;
        margin-bottom: 2rem;
        color: #000000;
      }

      .booksyBtn{
        text-align: center;
        width: 60%;
      }

      .ArrowAndText{
        font-size: 3rem;
      }
      .firstRowContact{
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
      }
     
      .location{
        margin-bottom: 2rem;
      }
      .hours{
        font-size: 1.7rem;
      }
      .openHours span {
        font-size: 2rem;
      }
      .secondRowContact{
        flex-direction: column;
        align-items: center;
        justify-content:space-between;
        padding: 2rem 0 2rem 0;
        height: 10rem;
      }
      .phoneNumber{
        font-size: 2rem;
      }
      .emailContact{
        font-size: 2rem;
      }
  }
  }
