@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&family=Kalam&family=Roboto:ital@1&display=swap');
.navbar
    {
        margin-top: 2rem;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        align-items: center;
        position:absolute;
        z-index: 90;
        width: 100%;
    }


.media
{
    width: 8rem;
    display: flex;
    color: white;
    justify-content: space-between;
}

.media_content a{
    transition: color 0.5s;
}

.media_content a:hover
{
    color: black;
}

.media_content a {
    color: white;
}

.btn
    {
        width: 6.6rem;
        height: auto;
        color: white;
        border-color: white;
        border-style: solid;
        border-width: 0.1rem;
        padding: 0.6rem;
        cursor: pointer;
        transition: background-color 0.5s, color 0.5s;
        display: flex;
        justify-content: center;
        text-decoration: none;
    }

 .btn_a {
    color: white;
    text-decoration: none;
}
.btn:hover
    {
        background-color: white;
        color:black;
        border-color: white;
    }

.burger_menu
    {
        display: none;
        color: white;
        position: absolute;
        right: 1.5rem;
        cursor: pointer;
    }

.mobileMenu
    {
        display: none;
        position: absolute;
        z-index: 100;
        background-color: white;
        width: 100%;
        height: 100vh;
        flex-direction: column;
    }

.headerMobileMenu
{
    width: 100%;
    display: flex;
    justify-content: center;
    flex: 1;
}

.logoMobileMenu
    {
        margin-top: 1rem;
        display: flex;
        font-size: 5rem;
        font-family: 'Dancing Script', cursive;
    }

.logoMobileMenu a
    {
        color: black;
        text-decoration: none;
    }

.leaveMenu
    {
        display: block;
        position: absolute;
        top: 3rem;
        right: 1.5rem;
        cursor: pointer;
    }

.mobileNavElements
    {
        width: 100%;
        display: flex;
        justify-content: center;
        flex: 5;
        align-items: center;
    }

.mobileNavElements ul
    {
        height: 70%;
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        list-style: none;
        font-size: 2rem;
        margin: 0;
        padding: 0;
    }

    .logo{
        width: auto;
        height: auto;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .logoImg{
        width: 25rem;
    }

 .navbar-links ul {
        margin: 0;
        padding: 0;
        display: flex;
        padding-right: 0.5rem;
    }

.navbar-links li
    {
        list-style: none;
        font-size: 1.4rem;
    }

.navbar-links li a
    {
        text-decoration: none;
        color: rgb(255, 255, 255);
        padding: 1rem;
        display: block;
        font-family: 'Roboto', sans-serif;
        cursor: pointer;
        transition: color 0.5s;
    }
    @media screen {
        @media (max-width: 850px){
           .media{
            display: none;
           }
           .btn{
            display: none;
           }
           .nav1{
            justify-content: center;
           }
          
           .burger_menu{
            display: flex;
           }
           .navbar-links {
            display: none;
           }
           .mobileMenu{
            display:flex;
           }
        }
      }


.navbar-links li a:hover
    {
        color: black;
    }


